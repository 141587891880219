const selectors = {
    videoBanner: '.js-video-banner',
    videoSource: $('.js-video-banner source'),
    desktopVideo: $('.js-video-banner source').data('desktopv'),
    mobileVideo: $('.js-video-banner source').data('mobilev'),
    controlButton: $('.js-control-video')
};

const breakpoint = {
    mobile: window.matchMedia('(max-width: 767px)').matches,
    desktop: window.matchMedia('(min-width: 768px)').matches
}

const classes = {
    videoPaused: 'paused'
};

if ( document.querySelectorAll(selectors.videoBanner).length > 0 ) {
    breakpoint.mobile ? selectors.videoSource.attr('src', selectors.mobileVideo) : selectors.videoSource.attr('src', selectors.desktopVideo);

    $(selectors.videoBanner)[0].load();

    $(selectors.controlButton).on('click', function() {
        if ( $(selectors.videoBanner)[0].paused ) {
            playVideo();
        } else {
            pauseVideo();
        }

        $(this).toggleClass(classes.videoPaused);
    });
}

function playVideo() {
    $(selectors.videoBanner)[0].play();
}

function pauseVideo() {
    $(selectors.videoBanner)[0].pause();
}
